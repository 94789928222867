import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { p, py } from 'styled-components-spacing';

import { DoctorGraphicIcon, OutpatientGraphicIcon, HospitalGraphicIcon } from '@nib/icons';
import { Section, Stack } from '@nib/layout';

import Accordion from '@nib-components/accordion';
import colors from '@nib-components/colors';
import Copy, { Bold } from '@nib-components/copy';
import FeaturePanel from '@nib-components/feature-panel';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';
import { colorTrueGreen } from '@nib-components/theme';

import HeroPanel from '../components/HeroPanel';
import Layout from '../components/Layout';
import UtilityButtons from '../components/UtilityButtons';
import VerticalFeaturePanel from '../components/VerticalFeaturePanel';
import MobileAppSection from '../components/MobileAppSection';
import metrics from '../metrics';
import { urlConstants } from '../constructs/constants';
import { UtilityLinkModel } from '../constructs/models';

// images
import DesktopHero from '../img/claims/hero/desktop.png';
import DesktopHero2x from '../img/claims/hero/desktop@2x.png';
import MobileHero from '../img/claims/hero/mobile.png';
import MobileHero2x from '../img/claims/hero/mobile@2x.png';
import TabletHero from '../img/claims/hero/tablet.png';
import TabletHero2x from '../img/claims/hero/tablet@2x.png';
import LoginMyNibImg from '../img/claims/how_to_claim_login.jpg';
import ChooseMakeAClaimImg from '../img/claims/how_to_claim_choose.jpg';
import FillInClaimFormImg from '../img/claims/how_to_claim_fill.png';
import { HeroPanelCopy } from '../components/styledComponents';

const title = 'Making A Claim On Your nib Health Insurance';
const metaDescription =
  'Need to make a claim on your consultation or treatment? There are a few ways nib members can make a claim, find out how.';

const utilityLinks: UtilityLinkModel[] = [
  {
    title: 'Find out now',
    subtitle: 'Are you covered?',
    url: '/am-i-covered',
  },
  {
    title: 'Find a Provider',
    subtitle: 'Need treatment?',
    url: urlConstants.findAProvider,
  },
  {
    title: 'Log in',
    subtitle: 'Looking for my nib?',
    url: urlConstants.mynib,
  },
  {
    title: 'Find a programme',
    subtitle: 'Managing your health?',
    url: '/health-management-programmes',
  },
];

const FeatureWrapper = styled(FeaturePanel.Feature)`
  box-shadow: rgb(221 221 221) 0px 4px 8px 0px;
  ${p(5)};
  h3 {
    color: ${colorTrueGreen};
  }
`;

const FeaturePanelWrapper = styled(FeaturePanel)`
  ${py(0)};
`;

const TreatmentCoverSection = () => (
  <Section role="section">
    <Stack space={4}>
      <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2" align="center">
        I need treatment, what do I do?
      </Heading>
      <Copy align="center" measure={false}>
        It’s best to check what you’re covered for before you see your{' '}
        <Link href={urlConstants.findAProvider}>healthcare provider</Link>.
      </Copy>
      <Copy align="center" measure={false}>
        You might need a pre-approval to confirm what you will be covered for. This depends on what
        type of consultation or treatment you’re having. Check below to see if you should get a
        pre-approval and how you can make a claim.
      </Copy>
      <FeaturePanelWrapper iconFill="trueGreen">
        <FeatureWrapper icon={DoctorGraphicIcon} title="Everyday">
          <Stack space={4}>
            <Copy>
              Everyday consultations and treatment include dentist, GP or physio visits. Depending
              on your policy, you may also have cover for getting new prescription glasses or
              contact lenses, chiropractor visits, mental health consultations and more.
            </Copy>
            <Copy>
              You can check your policy in <Link href={urlConstants.mynib}>my nib</Link> to see if
              you are covered for these services and how much you can claim. Most policies cover
              60-80% of your costs up to your benefit limit
            </Copy>
            <Copy>
              To claim, pay your healthcare provider on the day, then send us the receipt through{' '}
              <Link href={urlConstants.mynib}>my nib</Link> or the nib app.
            </Copy>
          </Stack>
        </FeatureWrapper>
        <FeatureWrapper icon={OutpatientGraphicIcon} title="Outpatient">
          <Stack space={4}>
            <Copy>
              If you've been referred to a specialist or surgeon, or a medical clinic for diagnostic
              investigations, check your policy in <Link href={urlConstants.mynib}>my nib</Link> to
              see if you are covered or to submit a pre-approval request.
            </Copy>
            <Copy>
              Once you know what you’re covered for, visit your healthcare provider then send us
              their invoice when you submit your claim.
            </Copy>
          </Stack>
        </FeatureWrapper>
        <FeatureWrapper icon={HospitalGraphicIcon} title="Hospital">
          <Stack space={4}>
            <Copy>
              For surgery or cancer treatment, we recommend that you request a pre-approval before
              your treatment. That way, you’ll know for sure what you’re covered for and claiming
              will be simple.
            </Copy>
            <Copy>
              Some healthcare providers also need a pre-approval letter before starting your
              treatment.
            </Copy>
            <Copy>
              Some healthcare providers in our{' '}
              <Link href={urlConstants.findAProvider}>First Choice network</Link> can take care of
              this paperwork for you, or you can request a pre-approval in{' '}
              <Link href={urlConstants.mynib}>my nib</Link>.
            </Copy>
          </Stack>
        </FeatureWrapper>
      </FeaturePanelWrapper>
    </Stack>
  </Section>
);

const StyledAccordionItem = styled(Accordion.Item)`
  h4 {
    color: ${colorTrueGreen};
  }
  p {
    color: ${colorTrueGreen};
  }
`;

const FaqSection = () => (
  <Section role="section" background="sunsetPink60">
    <Stack space={6}>
      <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h3">
        Frequently asked questions
      </Heading>
      <Accordion borderTop={false} borderBottom>
        <StyledAccordionItem
          title="Will I need to pay on the day for outpatient and hospital consultations or treatments?"
          titleComponent="p"
        >
          <Stack space={4}>
            <Copy measure={false}>
              If you visit a healthcare provider within our{' '}
              <Link href={urlConstants.findAProvider}>First Choice network</Link>, they can submit a
              claim for your eligible costs* so you won’t need to pay these. Other healthcare
              providers may ask you to pay all the costs on the day of your visit, even if you have
              pre-approval from nib. You can ask them about this before your appointment.
            </Copy>
            <Copy measure={false}>
              Some of the costs you may need to pay on the day could include:
            </Copy>
            <Copy measure={false}>
              <ul>
                <li>
                  An <Bold>excess payment</Bold> - the amount you contribute if you make a claim.
                  This only applies to hospital policies and is outlined in your Policy document.
                </li>
                <li>
                  Any costs that <Bold>aren’t covered under your plan</Bold> i.e. costs above your
                  benefit limit and/or not covered by your policy.
                </li>
                <li>
                  <Bold>Gap payments</Bold> - the difference between what a healthcare provider
                  charges and what the average market price is for treatment.
                </li>
              </ul>
            </Copy>
            <Copy measure={false}>
              A pre-approval will outline which of these costs, if any, you may be required to pay.
            </Copy>
            <Copy measure={false}>
              *If you see a First Choice healthcare provider for a claim covered by your policy, nib
              will pay 100% of your eligible costs up to your benefit limit, subject to any excess
              payable and in line with your policy terms.
            </Copy>
          </Stack>
        </StyledAccordionItem>
        <StyledAccordionItem title="How do I pay my excess?" titleComponent="p">
          <Stack space={4}>
            <Copy measure={false}>
              When you make a claim, your excess is deducted from the approved amount we will pay
              for your consultation or treatment. You need to pay this excess amount to your
              healthcare provider directly. They may ask for you to pay this on the day or send you
              an invoice.
            </Copy>
            <Copy measure={false}>
              Sometimes your consultation or treatment costs less than your excess. If this happens,
              you pay the full amount of your consultation or treatment to your healthcare provider
              as there are no remaining approved costs to be paid by us.
            </Copy>
            <Copy measure={false}>
              You choose your excess amount and, depending on the policy you have, you may pay your
              excess per claim or per year. You can request to increase or decrease this amount by
              contacting us. To find out how much your excess is and how it works, log in to{' '}
              <Link href={urlConstants.mynib}>my nib</Link> and check your Policy document and
              latest Acceptance or Renewal certificate.
            </Copy>
          </Stack>
        </StyledAccordionItem>
        <StyledAccordionItem title="Can someone make a claim for me?" titleComponent="p">
          <Copy measure={false}>
            To make your claim hassle-free, some First Choice network healthcare providers can
            submit pre-approvals and claims on your behalf. That means you can focus on getting
            well. When you use nib’s <Link href={urlConstants.findAProvider}>Find a Provider</Link>{' '}
            directory, you can filter your search results to find healthcare providers who also take
            care of your paperwork.
          </Copy>
        </StyledAccordionItem>
      </Accordion>
    </Stack>
  </Section>
);

const claimStepDescription = {
  title: 'How do I make a claim to get a payment refund?',
  subTitle:
    'If your healthcare provider doesn’t claim on your behalf, you’ll need to submit a claim. Scan or photograph any receipts or invoices from your healthcare provider, then follow these three easy steps:',
  footer:
    'We’ll email and let you know once your claim has been assessed or if we need more information.',
};

const claimStepFeatures = [
  {
    heading: 'Log in to my nib or open the nib app',
    content: function LoginMyNib() {
      return '';
    },
    image: LoginMyNibImg,
  },
  {
    heading: 'Choose “Make a Claim”',
    content: function ChooseMakeAClaim() {
      return '';
    },
    image: ChooseMakeAClaimImg,
  },
  {
    heading: 'Fill in the online form and attach the scans or photos of your receipts or invoices',
    content: function FillInClaimForm() {
      return '';
    },
    image: FillInClaimFormImg,
  },
];

const schema = {
  '@context': 'https://schema.org',
  '@type': 'WebPage',
  '@id': 'WebPage',
  identifier: 'https://www.nib.co.nz/claims',
  url: 'https://www.nib.co.nz/claims',
  name: 'How to make a claim on your nib health insurance | nib',
  description:
    'Need to make a claim on your consultation or treatment? There are a few ways nib members can make a claim, find out how.',
  isPartOf: 'https://www.nib.co.nz',
};
const JSONschema = JSON.stringify(schema);

const ClaimsPage = () => (
  <Layout>
    <Helmet>
      <title>{title} | nib</title>
      <meta name="description" content={metaDescription} />
      <script type="application/ld+json">{JSONschema}</script>
    </Helmet>
    <HeroPanel
      images={{
        desktop: DesktopHero,
        desktop2x: DesktopHero2x,
        mobile: MobileHero,
        mobile2x: MobileHero2x,
        tablet: TabletHero,
        tablet2x: TabletHero2x,
      }}
      title="How do I make a claim on my health cover?"
      nextSectionBackgroundColor={colors.sneezy}
      variation="condensed"
    >
      <HeroPanelCopy>
        Claiming back the cost of your consultation or treatment is simple and hassle-free.
      </HeroPanelCopy>
    </HeroPanel>
    <TreatmentCoverSection />
    <Section role="section" background="warmWhite60">
      <UtilityButtons buttons={utilityLinks} />
    </Section>
    <Section role="section">
      <VerticalFeaturePanel description={claimStepDescription} features={claimStepFeatures} />
    </Section>
    <MobileAppSection />
    <FaqSection />
  </Layout>
);

export default metrics({ pageName: 'claims' })(ClaimsPage);
